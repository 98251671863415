import { keyframes } from '@emotion/core'
import { formatDistanceToNow } from 'date-fns'
import { Dropbox } from 'dropbox'
import { navigate } from 'gatsby'
import { curry, remove, union } from 'ramda'
import React from 'react'
import { Subscribe } from 'unstated'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import SyncIcon from '@material-ui/icons/Sync'

import DropboxIcon from '../../components/dropbox-icon'
import Layout from '../../components/layout'
import DropboxContainer from '../../containers/DropboxContainer'
import SavingsContainer from '../../containers/SavingsContainer'

import { isBootstrapped } from '../../containers/withLocalStorage'
import { parseQueryString } from '../../util'
import withRoot from '../../withRoot'

import { legacyParse } from "@date-fns/upgrade/v2";

const { DROPBOX_CLIENT_ID = 'by18o5jexqf5b3p' } = process.env

const appendEntriesToBuckets = curry((entries, buckets) => {
  return union(
    buckets,
    entries
      .filter(entry => entry['.tag'] === 'file')
      .map(entry => ({
        id: entry.id,
        name: getFilenameWithoutExtension(entry.name),
        lastSynced: entry.client_modified,
        rev: entries.rev,
      }))
  )
})

const getFilenameWithoutExtension = filename => {
  const lastExt = filename ? filename.lastIndexOf('.') : -1

  if (lastExt > -1) {
    return filename.substring(0, lastExt)
  } else {
    return filename
  }
}

class DropboxBucketList extends React.Component {
  dbx = new Dropbox({
    clientId: DROPBOX_CLIENT_ID,
    accessToken: this.props.accessToken,
  })

  state = {
    loaded: false,
    creating: false,
    adding: false,
    deleting: null,
    buckets: [],
    bucketName: '',
    error: false,
    errorText: null,
  }

  componentDidMount() {
    this.dbx.filesListFolder({ path: '' }).then(this.handleFilesList)
  }

  handleFilesList = result => {
    // more buckets
    if (result.has_more) {
      this.setState(
        prevState => ({
          buckets: appendEntriesToBuckets(result.entries, prevState.buckets),
        }),
        () => {
          this.dbx
            .filesListFolderContinue({ cursor: result.cursor })
            .then(this.handleFilesList)
        }
      )
    } else {
      this.setState(prevState => ({
        loaded: true,
        buckets: appendEntriesToBuckets(result.entries, prevState.buckets),
      }))
    }
  }

  render() {
    const { loaded, buckets, adding } = this.state

    if (loaded) {
      if (buckets.length === 0 || adding) {
        return this.renderCreateBucket()
      } else {
        return this.renderBucketList()
      }
    }

    return <CircularProgress />
  }

  renderBucketList() {
    const { buckets, deleting } = this.state

    return <>
      <Typography variant="overline">Choose account to sync</Typography>
      <List>
        {buckets.map(bucket => (
          <ListItem
            disabled={deleting === bucket.id}
            button
            onClick={() =>
              this.selectBucket(
                bucket.id,
                bucket.name,
                bucket.lastSynced,
                bucket.rev
              )
            }
          >
            <ListItemText
              secondary={`Last synced ${formatDistanceToNow(legacyParse(bucket.lastSynced))} ago`}
            >
              {bucket.name}
            </ListItemText>
            {deleting !== bucket.id && (
              <ListItemSecondaryAction>
                <IconButton onClick={() => this.handleBucketDelete(bucket)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
            {deleting === bucket.id && (
              <ListItemSecondaryAction>
                <CircularProgress />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
        <Divider />
        <ListItem button onClick={() => this.handleAddAccount()}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText>Create new account</ListItemText>
        </ListItem>
      </List>
    </>;
  }

  renderCreateBucket() {
    return (
      <>
        <Typography variant="h6" gutterBottom>
          Create new account
        </Typography>
        <Typography variant="subtitle1">
          You can organize accounts separately based on whatever preference you
          have. For example, you could create an account per person, per
          household, or by device. You can sync accounts across devices or pick
          and choose what accounts are used where.
        </Typography>
        <FormControl>
          <TextField
            required
            variant="outlined"
            onChange={this.handleBucketNameChange}
            placeholder="Name"
          />
        </FormControl>

        <p>
          <Subscribe to={[SavingsContainer]}>
            {savingsData => (
              <Button
                disabled={this.state.creating}
                variant="contained"
                color="primary"
                onClick={() => this.handleBucketCreate(savingsData.state)}
              >
                Create
                {this.state.creating && (
                  <CircularProgress size={20} css={{ marginLeft: '0.5em' }} />
                )}
              </Button>
            )}
          </Subscribe>
          {this.state.adding && (
            <Button
              color="secondary"
              onClick={this.handleCancelAddAccount}
              css={{ marginLeft: '1em' }}
            >
              Cancel
            </Button>
          )}
        </p>

        {this.state.error && (
          <Typography variant="body2" color="error">
            {this.state.errorText}
          </Typography>
        )}
      </>
    )
  }

  handleAddAccount = () => {
    this.setState({ adding: true })
  }

  handleCancelAddAccount = () => {
    this.setState({ adding: false })
  }

  selectBucket(id, name, lastSynced, rev) {
    this.props.onBucketChange({ id, name, lastSynced, rev })
  }

  handleBucketNameChange = e => {
    this.setState({ bucketName: e.target.value })
  }

  handleBucketCreate = contents => {
    this.setState({ creating: true }, () =>
      this.dbx
        .filesUpload({
          path: `/${this.state.bucketName}.json`,
          mode: 'add',
          contents: JSON.stringify(contents),
          client_modified: new Date().toISOString().replace(/\.\d{3}/, ''),
          mute: true,
          autorename: false,
          strict_conflict: true,
        })
        .then(file => {
          this.setState({ creating: false }, () => {
            if (this.state.adding) {
              this.setState(prevState => ({
                adding: false,
                buckets: appendEntriesToBuckets(
                  [{ '.tag': 'file', ...file }],
                  prevState.buckets
                ),
              }))
            } else {
              this.selectBucket(
                file.id,
                getFilenameWithoutExtension(file.name),
                file.client_modified,
                file.rev
              )
            }
          })
        })
        .catch(res => {
          console.error(res)

          this.setState({
            creating: false,
            error: true,
            errorText: res.error.user_message
              ? res.error.user_message.text
              : res.error,
          })
        })
    )
  }

  handleBucketDelete = bucket => {
    this.setState({ deleting: bucket.id }, () =>
      this.dbx
        .filesDeleteV2({ path: bucket.id })
        .then(() => {
          this.setState(prevState => ({
            deleting: null,
            buckets: remove(
              prevState.buckets.indexOf(bucket),
              1,
              prevState.buckets
            ),
          }))
        })
        .catch(res => {
          console.error(res)
          this.setState({ deleting: null })
        })
    )
  }
}
DropboxBucketList.defaultProps = {
  dbx: null,
  onBucketChange: bucket => {},
}

class DropboxIntegration extends React.Component {
  state = { authenticating: false, bucket: this.props.bucket }

  getAccessTokenFromUrl() {
    return parseQueryString(this.props.location.hash).access_token
  }

  componentDidMount() {
    const accessTokenFromUrl = this.getAccessTokenFromUrl()

    if (accessTokenFromUrl) {
      const dbx = new Dropbox({
        clientId: DROPBOX_CLIENT_ID,
        accessToken: accessTokenFromUrl,
      })

      return this.setState({ authenticating: true }, () =>
        dbx.usersGetCurrentAccount().then(user => {
          this.props
            .onAuthenticatedUser(accessTokenFromUrl, user)
            .then(() =>
              this.setState({ authenticating: false }, () =>
                navigate('/integrations/dropbox')
              )
            )
        })
      )
    }
  }

  login = () => {
    const dbx = new Dropbox({
      clientId: DROPBOX_CLIENT_ID,
    })

    const authUrl = dbx.getAuthenticationUrl(
      window.location.origin + '/integrations/dropbox'
    )

    window.location.href = authUrl
  }

  revokeAccess = () => {
    const dbx = new Dropbox({
      clientId: DROPBOX_CLIENT_ID,
      accessToken: this.props.accessToken,
    })
    dbx.authTokenRevoke().then(() => this.props.onRevokeAccess())
  }

  render() {
    const RENDER_STATE = {
      LOADING: 0,
      NOT_CONNECTED: 1,
      NO_ACCOUNT: 2,
      CONNECTED: 3,
    }

    let renderState

    if (this.state.authenticating) {
      renderState = RENDER_STATE.LOADING
    } else if (!this.props.isAuthenticated) {
      renderState = RENDER_STATE.NOT_CONNECTED
    } else if (this.props.isAuthenticated && !this.state.bucket) {
      renderState = RENDER_STATE.NO_ACCOUNT
    } else if (this.props.isAuthenticated && this.state.bucket) {
      renderState = RENDER_STATE.CONNECTED
    }

    return (
      <Paper css={{ padding: 16 }}>
        {renderState === RENDER_STATE.LOADING && <CircularProgress />}
        {renderState === RENDER_STATE.NOT_CONNECTED &&
          this.renderUnauthenticated()}
        {renderState === RENDER_STATE.NO_ACCOUNT && this.renderChooseBucket()}
        {renderState === RENDER_STATE.CONNECTED && this.renderConnected()}
      </Paper>
    )
  }

  renderUnauthenticated() {
    return (
      <div css={{ textAlign: 'center' }}>
        <div>
          <DropboxIcon css={{ fontSize: '120px' }} />
        </div>
        <Typography gutterBottom>
          You haven't connected your Dropbox account yet. You can use Dropbox to
          sync multiple accounts across your devices.
        </Typography>
        <Button
          variant="contained"
          onClick={this.login}
          color="primary"
          css={{ marginTop: 16 }}
        >
          Connect
        </Button>
      </div>
    )
  }

  renderChooseBucket() {
    return (
      <DropboxBucketList
        accessToken={this.props.accessToken}
        onBucketChange={this.handleBucketChange}
      />
    )
  }

  renderConnected() {
    return (
      <div css={{ textAlign: 'center' }}>
        <div css={{ marginBottom: 16 }}>
          <div>
            <DropboxIcon css={{ fontSize: '120px' }} />
          </div>
          <Typography variant="body1" gutterBottom>
            Your Dropbox is connected
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {this.props.accountName.display_name}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {this.state.bucket.name} account
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Last synced{' '}
            <abbr title={this.state.bucket.lastSynced}>
              {formatDistanceToNow(legacyParse(this.state.bucket.lastSynced))}
            </abbr>{' '}
            ago
          </Typography>
        </div>
        <Button
          variant="contained"
          color="secondary"
          onClick={this.changeBucket}
        >
          Change account
        </Button>{' '}
        <Button onClick={this.revokeAccess}>Disconnect</Button>
      </div>
    );
  }

  handleBucketChange = bucket => {
    this.setState({ bucket }, () => this.props.setBucket(this.state.bucket))
  }

  changeBucket = () => {
    this.setState({ bucket: null })
  }
}
DropboxIntegration.defaultProps = {
  isAuthenticated: false,
  loaded: false,
  location: null,
  accessToken: null,
  accountName: null,
  bucket: null,
  onRevokeAccess() {},
  onAuthenticatedUser(accessToken, user) {},
  setBucket(bucket) {},
}

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

class DropboxAppBarContent extends React.Component {
  render() {
    return (
      <>
        <Subscribe to={[DropboxContainer, SavingsContainer]}>
          {(dropboxStore, savingsData) =>
            dropboxStore.hasAccount() && (
              <IconButton
                onClick={() => {
                  dropboxStore.syncImmediate([], savingsData.state, nextState =>
                    savingsData.setState(nextState)
                  )
                }}
              >
                <SyncIcon
                  color="inherit"
                  css={
                    dropboxStore.state.syncing
                      ? { animation: `${spinAnimation} 2s infinite` }
                      : {}
                  }
                />
              </IconButton>
            )
          }
        </Subscribe>
      </>
    )
  }
}

const DropboxPage = ({ location }) => (
  <Layout
    title="Dropbox Sync"
    appBarContent={<DropboxAppBarContent />}
    pathname={location.pathname}
  >
    <Subscribe to={[DropboxContainer, SavingsContainer]}>
      {(dropboxStore, savingsData) => {
        if (!isBootstrapped(dropboxStore)) {
          return <CircularProgress />
        }

        const handleSetBucket = bucket =>
          dropboxStore.useAndSyncBucket(bucket, nextState => {
            savingsData.setState(nextState)
          })

        return (
          <DropboxIntegration
            location={location}
            isAuthenticated={!!dropboxStore.state.accessToken}
            accessToken={dropboxStore.state.accessToken}
            accountName={dropboxStore.state.accountName}
            bucket={dropboxStore.state.bucket}
            onAuthenticatedUser={dropboxStore.authenticate}
            onRevokeAccess={dropboxStore.revoke}
            setBucket={handleSetBucket}
          />
        )
      }}
    </Subscribe>
  </Layout>
)

export default withRoot(DropboxPage)
